import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // Training Category
    addTrainingCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/training-category', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTrainingCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/training-category/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTrainingCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/training-category/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTrainingCategories(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/training-category', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Training
    addTraining(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/training', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    reAssignTraining(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/training-reassign', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTraining(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/training/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTraining(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/training/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTrainings(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/training', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSingleTraining(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/training/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadVideo(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/training-upload-video', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteVideo(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/training-delete-video', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Training
    // questions
    addQuestion(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/training-questions', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateQuestion(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/training-questions/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchQuestions(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/training-questions', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteQuestion(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/training-questions/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeQuestionOrdering(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/training-questions-change-ordering', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // question options
    addQuestionOption(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/training-question-options', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateQuestionOption(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/training-question-options/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchQuestionOptions(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/training-question-options', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteQuestionOption(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/training-question-options/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // my trainings
    fetchMyTrainings(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/my-trainings', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMySingleTraining(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/my-trainings/${data.id}`, { id: data.id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSingleQuestion(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/my-trainings-single-question', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // quiz
    startQuiz(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/training-start-quiz', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    selectQuestionAnswer(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/training-select-question-answer', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSingleTrainingHistory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/my-training-history/${data.id}`, { id: data.id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
