<template>
  <div>

    <!-- content -->
    <div class="blog-detail-wrapper">
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <b-card

            :title="trainingData.name"
          >

            <!-- eslint-disable vue/no-v-html -->
            <div
              class="blog-content"
              style="user-select:none;"
              oncontextmenu="return false;"
              v-html="trainingData.content"
            />

            <!-- eslint-enable -->
            <hr class="my-2">
            <div>
              <b-badge :variant="getTrainingStatusName(trainingData).variant">
                {{ getTrainingStatusName(trainingData).text }}
              </b-badge>
              <hr>
              <b-button
                v-if="trainingData.startable_status.startable==true"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="startQuiz"
              >
                {{ getTrainingStatusName(trainingData).is_continue?'Teste Devam Et':'Eğitim Testine Başla' }}
              </b-button>

            </div>
            <div v-if="trainingData.history!=null">
              <label>{{ $t('Eğitim test tamamlama oranım') }}</label>
              <b-progress
                :max="100"
              >
                <b-progress-bar :value="trainingData.history.complete_stats.complete_rate">
                  <strong>{{ trainingData.history.complete_stats.answer_count }} / {{ trainingData.history.complete_stats.question_count }}</strong>
                </b-progress-bar>
              </b-progress>
            </div>
          </b-card>
        </b-col>
        <!--/ blogs -->

        <!-- Leave a Blog Comment -->

        <!--/ Leave a Blog Comment -->
      </b-row>
      <!--/ blogs -->
    </div>
    <!--/ content -->

  </div>
</template>

<script>
import {

  BCard, BRow, BCol, BProgress, BProgressBar, BButton, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import trainingStoreModule from '../trainingStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BProgress,
    BBadge,
    BProgressBar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search_query: '',
      commentCheckmark: '',
      blogDetail: [],
      blogSidebar: {},
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
    }
  },
  methods: {
    getTrainingStatusName(item) {
      const statusData = {
        variant: 'primary',
        text: '',
        startable: true,
        is_continue: false,
      }
      const st = item.startable_status
      if (st.last_history === null && st.startable) {
        statusData.startable = true
        statusData.variant = 'primary'
        statusData.text = 'Eğitim Testine Başlanmadı'
      } else if (st.continius_history != null && st.startable) {
        statusData.startable = true
        statusData.is_continue = true
        statusData.variant = 'warning'
        statusData.text = 'Eğitim Sınavınız Devam Ediyor. Süreniz Bitmeden Tamamlayınız'
      } else if (st.last_history != null && st.startable === false && st.is_success === false && st.is_timeover === false) {
        statusData.startable = false
        statusData.variant = 'secondary'
        statusData.text = `Eğitim Sınavına ${this.$format_datetime(st.test_next_start_date)} Tarihinde Tekrar Girebilirsiniz.`
      } else if (st.last_history != null && st.startable) {
        statusData.startable = true
        statusData.variant = 'danger'
        statusData.text = 'Eğitim Sınavına Tekrar Giriniz'
      } else if (st.is_success) {
        statusData.startable = false
        statusData.variant = 'success'
        statusData.text = 'Eğitimi Başarıyla Tamamladınız.'
      }
      return statusData
    },

    startQuiz() {
      if (this.getTrainingStatusName(this.trainingData).is_continue) {
        store.dispatch('app-training/startQuiz', { training_id: this.trainingData.id })
          .then(response => {
            router.push({ name: 'training-quiz', params: { training_history_id: response.data.data.id } })
          })
          .catch(() => {

          })
        return
      }
      this.$swal({
        title: this.$t('Eğitim Testini Başlatmak İstiyor Musunuz ?'),
        text: this.$t('Bu eğitim testine başladığınızda soruları verilen süre içerisinde çözmeniz gerekmektedir. Çözdüğünüz doğru sorular için eğitim puanı kazanacaksınız eğer sınav süresinde tamamlanmaz ise geçersiz olacaktır.'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Başlat'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-training/startQuiz', { training_id: this.trainingData.id })
            .then(response => {
              router.push({ name: 'training-quiz', params: { training_history_id: response.data.data.id } })
            })
            .catch(() => {

            })
        }
      })
    },
  },
  setup() {
    const trainingData = ref(null)

    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-training'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, trainingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })
    store.dispatch('app-training/fetchMySingleTraining', { id: router.currentRoute.params.id })
      .then(response => {
        trainingData.value = response.data.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          trainingData.value = undefined
        }
      })
    return {
      trainingData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
